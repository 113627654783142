.hover-box {
  position: relative;
  overflow: hidden;
  .hover-line {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all .5s cubic-bezier(0.5, 0.1, 0, 1);
    height: 2px;
    width: 100%;
    background-color: var(--theme-color);
  }
}

.hover-box-static {
  &:hover {
    cursor: pointer;
    .hover-line {
      transform: translate(-200%, -50%);
    }
  }
}

.hover-box-active {
  .hover-line {
    transform: translate(-200%, -50%);
  }
  &:hover {
    cursor: pointer;
    .hover-line {
      transform: translate(-50%, -50%);
    }
  }
}
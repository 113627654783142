.slider-box {
  position: relative;
  overflow: hidden;
  &_children {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
  .active {
    transform: translateY(0%);
  }
  .normal {
    transform: translateY(100%);
  }
}



.loading {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  color: #fff;
  transition: all 0.3s linear 0.6s;
  align-items: flex-end;
  & > div {
    height: 100%;
    width: 25%;
    background-color: #000;
    overflow: hidden;
  }
  & > div:nth-child(1) {
    transition: all 0.6s cubic-bezier(0.5, 1, 0.89, 1) 0.2s;
  }
  & > div:nth-child(2) {
    transition: all 0.6s cubic-bezier(0.5, 1, 0.89, 1) 0.4s;
  }
  & > div:nth-child(3) {
    transition: all 0.6s cubic-bezier(0.5, 1, 0.89, 1) 0.6s;
  }
  & > div:nth-child(4) {
    transition: all 0.6s cubic-bezier(0.5, 1, 0.89, 1) 0.8s;
  }
}

.loading-visible {
  & > div {
    height: 100%;
  }
}

.loading-hidden {
  pointer-events: none;
  & > div {
    height: 0%;
    p {
      opacity: 0;
    }
  }
}

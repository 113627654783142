@import './styles/iconfont.scss';
@import './styles/responsive.scss';
@import './styles/layout.scss';
@import './styles/flex.scss';
@import './styles/font.scss';

@media (prefers-color-scheme: light) {
  body {
    --theme-bg: #ffffff;
    --theme-color: #121212;

    --theme-line-2: rgba(18, 18, 18, 0.2);

    --theme-about-bg: #ffffff;
    --theme-body-bg: #f9f9f9;
    --theme-body-about-btn: rgba(18, 18, 18, 0.1);
    --theme-project-detail-img-bg: rgba(18, 18, 18, 0.9);
  }
}

@media (prefers-color-scheme: dark) {
  body {
    --theme-bg: #121212;
    --theme-color: #ffffff;

    --theme-line-2: rgba(252, 252, 252, 0.2);

    --theme-about-bg: #121212;
    --theme-body-bg: #222;
    --theme-body-about-btn: rgba(255, 255, 255, 0.1);
    --theme-project-detail-img-bg: rgba(252, 252, 252, 0.9);
  }
}

@include mobile() {
  body {
    --head-height: 80px;
    --head-padding: 0 24px;

    --body-padding-num: 24px;
  }
  .mobile-hidden {
    display: none;
  }
}

@include pc() {
  body {
    --head-height: 94px;
    --head-padding: 0 50px;

    --body-padding-num: 50px;
  }
  .pc-hidden {
    display: none;
  }
}

// @include pc-small() {

// }

// @include pc-large() {

// }

* {
  margin: 0;
  padding: 0;
  font-size: inherit;
  color: inherit;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 14px;
  color: var(--theme-color);
}

#root {
  height: 100%;
  width: 100%;
}

:root {
  color-scheme: light dark;
}

.transition {
  transition: all 0.3s ease-in-out;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.cursor-not-allowed:hover {
  cursor: not-allowed;
}

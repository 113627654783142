@import '../../styles/responsive.scss';

.needcode {
  height: 100%;
  width: 100%;
  background-color: var(--theme-bg);
}

@media (prefers-color-scheme: light) {
  .needcode {
    --logo: url('../../../public/logo-dark.png');
    --logo-active: url('../../../public/logo-dark.png');
  }
}

@media (prefers-color-scheme: dark) {
  .needcode {
    --logo: url('../../../public/logo-light.png');
    --logo-active: url('../../../public/logo-light.png');
  }
}

%fixedbar {
  position: fixed;
  left: 0;
  height: var(--head-height);
  width: 100%;
  box-sizing: border-box;
  padding: var(--head-padding);
}

%logo {
  width: 160px;
  height: 30px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.head {
  @extend %fixedbar;
  top: 0;
  z-index: 999;
  .logo {
    @extend %logo;
    background-image: var(--logo);
  }
}

.head-modal {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  transition: all 0.8s cubic-bezier(0.5, 0.1, 0, 1);
  background-color: var(--theme-about-bg);
  overflow: hidden;
  &_head {
    height: var(--head-height);
    padding: var(--head-padding);
    .logo {
      @extend %logo;
      background-image: var(--logo-active);
    }
  }
  &_foot {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    height: var(--head-height);
  }
}

.head-modal-close {
  height: 0%;
}

.head-modal-open {
  height: 100%;
}

.foot {
  @extend %fixedbar;
  left: 0;
  bottom: 0;
  z-index: 998;
  white-space: nowrap;
  a {
    text-decoration: none;
  }
  .beian-icon {
    width: 16px;
    height: auto;
  }
}

@include mobile() {
  .foot {
    transform: scale(0.8);
    .beian {
      position: absolute;
      left: 0px;
    }
    .beian-icon {
      width: 10px;
    }
  }
}

.body {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: var(--theme-body-bg);
  .project-wrap {
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  .project {
    height: 100%;
    transition: all 0.3s;
    .btn {
      border-radius: 8px;
      height: 36px;
      width: 90px;
      border: 1px solid var(--theme-color);
      &:hover {
        background-color: var(--hover-color);
        border-color: var(--hover-color);
        color: var(--theme-bg);
      }
    }
  }
  .project-poster {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    overflow: hidden;
    border-radius: 20px;
    background-color: #e9e9e9;
    transition: opacity 0.4s 4.5s;
    & > div {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      transition: all 0.8s cubic-bezier(0.34, 1.26, 0.64, 1);
      .poster {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        transition: all 0.6s;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
  .project-indicator {
    position: absolute;
    left: var(--body-padding-num);
    top: 50%;
    transform: translateY(-50%);
    & > div {
      height: 10px;
      width: 10px;
      box-sizing: border-box;
      border-radius: 50%;
      background-color: var(--theme-bg);
      border: 1px solid var(--theme-color);
      margin: 3px 0;
    }
    & > div.active {
      background-color: var(--theme-color);
    }
  }

  .project-animation {
    position: fixed;
    z-index: 99999;
    bottom: -50vh;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    pointer-events: none;
  }

  .project-animation-visible {
    animation: project-animation 2s 1 forwards;
  }

  .project-animation-hidden {
    height: 50vh;
    width: 50vw;
  }

  @keyframes project-animation {
    0% {
      height: 50vh;
      width: 50vw;
    }

    50% {
      height: 200vh;
      width: 200vw;
    }

    51% {
      height: 200vh;
      width: 200vw;
      transform: translate(-50%, 0%);
    }

    100% {
      transform: translate(-50%, -200vh);
    }
  }

  .project-detail {
    position: fixed;
    z-index: 99998;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--theme-bg);
    box-sizing: border-box;
    padding: var(--body-padding-num);
    overflow: auto;
    &_close {
      position: fixed;
      top: var(--body-padding-num);
    }
    &_info {
      position: absolute;
      left: 50%;
      top: 0%;
      transform: translate(-50%, 0%);
      .list {
        .item {
          height: 60px;
          border-bottom: 1px solid var(--theme-line-2);
        }
      }
      %img {
        width: 100%;
        height: auto;
        border-radius: 10px;
        transition: all 0.3s;
      }
      .image {
        width: 100%;
        flex-grow: 1;
        box-sizing: border-box;
        position: relative;
        img {
          @extend %img;
        }
      }
    }
  }

  .project-detail-transition-enter-done {
    opacity: 1;
  }

  .project-detail-transition-exit {
    animation: backOutDown .9s backwards;
  }

  .project-detail-transition-exit-done {
    opacity: 0;
    pointer-events: none;
  }
}
